<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" />
    <div class="page_box">
      <div class="pro-type-box">
        <div class="switch_name">商品类型</div>
        <div class="pro-choose-box">
          <div class="pro-type-item" @click="info.pro_type = 1;">
            <van-icon name="checked" color="#1588f5" size="3.2rem" v-if="info.pro_type == 1" />
            <van-icon name="circle" color="#c8c9cc" size="3.2rem" v-else />
            <div class="mg-lt20">
              <div>普通商品</div>
              <div class="color-999 ft-20 mg-tp15">实体商品，有库存管理</div>
            </div>
          </div>
          <div class="pro-type-item mg-lt60" @click="info.pro_type = 2;">
            <van-icon name="checked" color="#1588f5" size="3.2rem" v-if="info.pro_type == 2" />
            <van-icon name="circle" color="#c8c9cc" size="3.2rem" v-else />
            <div class="mg-lt20">
              <div>服务项目</div>
              <div class="color-999 ft-20 mg-tp15">理发、课程等，无库存管理</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 上半截,上传图片以及输入条码 -->
      <div class="top_box">
        <div class="uploader">
          <!-- <van-uploader
                      upload-text="添加商品图片"
                      v-model="fileList"
                      :after-read="uploadIDcardFront"
                      :max-count="1"
                    /> -->
          <van-uploader :after-read="uploadIDcardFront" :max-count="1" v-model="fileList" accept="image/*" :disabled="shop.user_model != 1"></van-uploader>
          <!--<img :src="info.img" v-else class="pro-img" />-->
        </div>
        <div class="add_input">
          <div class="input_box">
            <div class="input_name add-required">条码</div>
            <input type="number" ref="brcodeinput" class="input_index1" v-model="info.bar_code" placeholder="扫描商品码时，请务必保证光标聚焦此处" :disabled="shop.user_model != 1" />
            <van-button v-if="shop.user_model == 1" class="input_btn" :loading="bool_code" @click="sc_code">系统生成</van-button>
          </div>
          <div class="flex flex-ac nowrap">
            <div class="input_box" style="width: 50%;">
              <div class="input_name add-required">品名</div>
              <input type="text" class="input_index1" style="width: 49.4rem;" v-model="info.name" :disabled="shop.user_model != 1" @blur="setCode" />
            </div>
            <div class="input_box" style="width: 49.9%;">
              <div class="input_name add-required-code">助记码</div>
              <input type="text" class="input_index1" style="width: 49.4rem;" v-model="info.code" :disabled="shop.user_model != 1" placeholder="根据商品名称自动生成" />
            </div>
          </div>
          <div class="input_box">
            <div class="input_name add-required">分类</div>

            <!-- <div class="choose"> -->
            <!--  @click="openClassify" -->
            <!-- <van-icon name="arrow-down" /> -->
            <!-- {{ info.c_name }} -->
            <!-- </div> -->
            <!--<input type="text" class="input_index1" v-model="info.c_name" disabled/>-->
            <div @click="
                showPicker = true;
                pickerType = 1;
              " style="
                width: 110rem;
                background-color: #fff;
                padding-left: 2rem;
                text-align: left;
                height: 7.2rem;
              ">
              {{ goodsTypeName }}
            </div>
          </div>
          <div class="input_box border_btm">
            <div class="input_name add-required">售价</div>
            <input v-if="platform_name == 'android'" v-model="info.fact_price" placeholder="称重商品按kg计算" class="input_index2 input_index3" 
              :disabled="shop.user_model != 1" readonly @click="openKeyboard('fact_price')" />
            <input v-else v-model="info.fact_price" placeholder="称重商品按kg计算" type="number" class="input_index2 input_index3" :disabled="shop.user_model != 1" />
            <div class="input_name">会员价</div>
            <input v-if="platform_name == 'android'" v-model="info.member_price" placeholder="称重商品按kg计算" class="input_index2 input_index3" 
              :disabled="shop.user_model != 1" readonly @click="openKeyboard('member_price')" />
            <input v-else v-model="info.member_price" placeholder="称重商品按kg计算" type="number" class="input_index2 input_index3" :disabled="shop.user_model != 1" />
            <div class="input_name">成本价</div>
            <input v-if="platform_name == 'android'" class="input_index2" style="width:16rem;" v-model="info.price" 
              :disabled="shop.user_model != 1" readonly @click="openKeyboard('price')" />
            <input v-else type="number" class="input_index2" style="width:16rem;" v-model="info.price" :disabled="shop.user_model != 1" />
            <div class="input_name">排序</div>
            <input v-if="platform_name == 'android'" class="input_index2" style="width:16rem;" v-model="info.sort" 
              :disabled="shop.user_model != 1" readonly @click="openKeyboard('sort')" />
            <input v-else type="number" class="input_index2" style="width:16rem;" v-model="info.sort" :disabled="shop.user_model != 1" />
          </div>
        </div>
      </div>
      <!-- 下半截表格,展示用功能 -->
      <div class="bottom_box" :class="{'bottom-box-line': info.pro_type == 2,}">
        <div class="units">
          <div class="units_name add-required">商品单位</div>
          <div @click="is_show_choose_unit = true;" 
            style="
              width: calc(100% - 16rem);
              background-color: #fff;
              padding-left: 2rem;
              text-align: left;
              height: 7.2rem;
            "
          >
            {{ dwName }}
            <span style="float: right; margin-right: 1rem; color: red">称重商品请选择称重单位</span>
          </div>
        </div>
        <div class="units" v-if="info.pro_type == 1">
          <div class="units_name add-required">库存</div>
          <input v-if="platform_name == 'android'" class="units_input" v-model="info.stock" 
            :disabled="shop.user_model != 1 || name == 'editorProduct'" readonly @click="openKeyboard('stock')" />
          <input v-else type="number" class="units_input" v-model="info.stock" :disabled="shop.user_model != 1 || name == 'editorProduct'" />
          <span v-if="name == 'editorProduct'" style="float: right; margin-right: 1rem; color: red; background: #efefef4d;">
            调整库存请打开<span class="color-1588F5 text-underline" @click="toStock">库存管理</span>
          </span>
        </div>
        <div class="units" v-if="info.pro_type == 1">
          <div class="units_name">保质期</div>
          <input v-if="platform_name == 'android'" class="units_input" v-model="info.quality" 
            :disabled="shop.user_model != 1" readonly @click="openKeyboard('quality')" />
          <input v-else type="number" class="units_input" v-model="info.quality" :disabled="shop.user_model != 1" />
          <span style="float: right; margin-right: 1rem;">天</span>
        </div>
      </div>
      <div class="bottom_box mg-bt50 bottom-box-line">
        <div class="units">
          <div class="units_name">商品描述</div>
          <input type="text" class="units_input" v-model="info.desc" :disabled="shop.user_model != 1" />
        </div>
      </div>
      <!-- 底部确认框 -->
      <div class="foot_box">
        <div class="switch">
          <div class="switch_name">商品状态</div>
          <div class="switch_type">
            <van-switch v-model="info.status" :active-value="1" :inactive-value="0" :disabled="shop.user_model != 1" size="3rem" />
          </div>
          <div v-if="info.status == 1">启用</div>
          <div v-if="info.status == 0">禁用</div>
        </div>
      </div>
      <div style="height: 13rem;"></div>
      <!--<van-button class="foot_btn foot_submit" @click="submit()"-->
        <!--&gt;删除</van-button-->
        <!--&gt;-->
      </div>
      <div class="foot_btn_box" v-if="shop.user_model == 1">
        <van-button v-if="name == 'editorProduct'" class="foot_btn" style="margin-right: 10rem; background: #e60012; color: #fff;" @click="openDialog()">删除</van-button>
        <van-button v-else class="foot_btn" style="margin-right: 10rem;" @click="writeOff">取消</van-button>
        <van-button class="foot_btn foot_submit" @click="submit()">保存</van-button>
      </div>
    <!-- <div class="delGoods" v-if="name == 'editorProduct' && shop.user_model == 1">
      <van-button class="foot_btn foot_submit" @click="delGoods()">删除</van-button>
    </div> -->

    <van-popup v-model="Classifyopen" closeable>
      <div class="popBox">
        <div class="pop_title">分类</div>
        <div class="pop_index">
          <van-radio-group v-model="radio">
            <van-cell-group>
              <van-cell title="分类1" clickable @click="radio = '1'" v-for="(item, i) in 20" :key="i">
                <template #right-icon>
                  <van-radio name="1" checked-color="#1588F5" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <div class="pop_btn">
          <van-button class="btn" @click="choseClassify">取消</van-button>
          <van-button class="btn submit_btn" @click="chooseClassify()">确定
          </van-button>
        </div>
      </div>
    </van-popup>
    <!-- 唯一div收尾 -->

    <!--分类-->
    <van-popup v-model="showPicker" round position="bottom" class="popup-picker-custom">
      <van-picker v-show="pickerType == 1" show-toolbar :default-index="goodsTypePickerIndex" :columns="goodsTypeList" @cancel="showPicker = false" @confirm="onConfirm" />
      <van-picker v-show="pickerType == 2" show-toolbar :default-index="dwPickerIndex" :columns="dwList" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>

    <van-popup v-model="codeShow" round>
      <div class="popups">
        <div>请用扫码枪对准下方数字</div>
        <div>{{info.bar_code}}</div>
      </div>
    </van-popup>

    <image-cropper v-if="isShowCropper" @closeCropperDialog="closeCropperDialog" :imgObj="croimgObj" @confirmCropperDialog="confirmCropperDialog"></image-cropper>
  
    <!-- 选择单位弹窗 -->
    <div class="choose-unit-modal" v-if="is_show_choose_unit">
      <div class="modal-content-box relative" style="z-index: 2002;">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closeChooseUnit" />

        <div class="wrapper">
          <!-- 标题 -->
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">选择单位</div>

          <div class="mocontent">
            <div class="cont-title-box">
              <div v-for="(item, i) in unit_type_options" 
                class="cont-title-item" 
                :class="{'cont-title-item-ac': unit_type == item.type}" 
                @click="changeUnitType(item)"
              >{{ item.name }}</div>
            </div>
            <div class="unit-content">
              <div v-for="(item, index) in dwList" 
                @click="chooseUnit(item)"
                class="unit-item"
                :class="{
                  'actived-unit-item': dwName == item.text,
                }"
              >{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div style="height: 3rem;"></div>
      </div>
    </div>
    <!-- 选择单位弹窗 -->

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
    ></popupOfDialog>

    <van-number-keyboard 
      v-model="numVal" 
      :title="numVal" 
      :show="show_keyboard" 
      extra-key="." 
      @blur="show_keyboard = false;" 
      @input="handleNumChange" 
      @delete="handleNumDelete"
      class="otherEatNum-nk" 
    />
  </div>
</template>

<script>
import { Toast } from "vant";
import titlebar from "@/views/component/titlebar";
import { mapGetters } from 'vuex';
import imageCropper from "@/views/component/image-cropper.vue";
import popupOfDialog from "../component/popupOfDialog.vue";
import { pinyin } from 'pinyin-pro';

export default {
  async created() {
    this.name = this.$route.query.name || ""; //拿到传过来的标题
    this.id = this.$route.query.id || null;
    this.info.c_id = this.$route.query.categoryId || '';
    this.$nextTick(() => {
      this.$refs.brcodeinput.focus();
      this.$refs.brcodeinput.setAttribute("readonly", "readonly");
      setTimeout(() => {
        this.$refs.brcodeinput.removeAttribute("readonly");
      }, 200);
    }, 100)
    await this.getDetail();
    await this.getGoodsType();
    await this.goodsUnit();
  },
  components: {
    titlebar: titlebar,
    'image-cropper': imageCropper,
    popupOfDialog,
  },
  data() {
    return {
      codeShow: false,
      tid: null, // 判断是新增还是编辑跳转
      id: null, // 商品ID
      info: {
        img: "",
        bar_code: "",
        name: "",
        c_id: "",
        price: "",
        fact_price: "",
        member_price: "",
        dw: "",
        status: 1,
        stock: "",
        desc: "",
        pro_type: 1,
        sort: '',
        quality: '',
        code: '', // 助记码
      }, // 商品详情

      fileList: [], //上传图片
      goodsTypeList: [], // 商品分类列表
      goodsTypeName: "", // 商品选中名
      dwList: [], // 单位列表
      dwName: "", // 显示的单位名
      goodsTypePickerIndex: 0,
      dwPickerIndex: 0,
      showPicker: false, // 显示picker
      pickerType: 1, //
      Classifyopen: false, //开启弹窗
      radio: "1",
      checked: true, //是否打印标签
      bool_code: false, //控制生成条码
      isShowCropper: false,
      croimgObj: {},
      is_show_choose_unit: false,
      unit_type: 1,
      unit_type_options: [
        { type: 1, name: '普通单位' },
        { type: 2, name: '称重单位' },
      ],
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
      numberModel: '', // 页面数据参数
      numVal: '', // 数字键盘显示金额
      numValArr: [], // 数字键盘金额数组
      show_keyboard: false,
      platform_name: localStorage.nowDevice || '',
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'}),
  },
  methods: {
    setCode() {
      const result = pinyin(this.info.name, { pattern: 'first', toneType: 'none', type: 'array' }).join('').toUpperCase();
      console.log(result);
      this.info.code = result;
    },
    handleNumChange(e) {
			this.numValArr.push(e);
			this.numVal = this.numValArr.join('');
			this.info[`${this.numberModel}`] = this.numVal;
		},
		handleNumDelete(e) {
			this.numValArr.pop();
			this.numVal = this.numValArr.length > 0 ? this.numValArr.join('') : '';
			this.info[`${this.numberModel}`] = this.numVal;
		},
    openKeyboard(name) {
      console.log(this.info[`${name}`]);
      this.numberModel = name;
      this.numVal = this.info[`${name}`] ? this.info[`${name}`].toString() : '';
      this.numValArr = this.info[`${name}`] ? this.info[`${name}`].toString().split('') : [];
      this.show_keyboard = true;
    },
    toStock() {
      this.$router.push({
        name: 'featureSet',
        query: {
          name: 'featureSet',
          active: 9,
        }
      })
    },
    changeUnitType(item) {
      if (this.unit_type != item.type) {
        this.unit_type = item.type;
        this.goodsUnit();
      }
    },
    chooseUnit(item) {
      this.info.dw = item.text;
      this.dwName = item.text;
      this.closeChooseUnit();
    },
    openChooseUnit() {
      this.is_show_choose_unit = true;
    },
    closeChooseUnit() {
      this.is_show_choose_unit = false;
    },
    confirmCropperDialog(data) {
      if (data.file) {
        this.updateShopImage(data.file);
      } else {
        this.$toast('图片裁剪失败');
      }
    },
    async updateShopImage(file) {
      console.log(file);
      const formData = new FormData();
      formData.append("file", file);
      let res = await this.$api.upload(formData);
      this.isShowCropper = false;
      if (res.code == 1) {
        this.info.img = res.data.str;
        this.fileList = [
          {
            url: res.data.str,
          },
        ];
      } else {
        this.fileList = [];
      }
    },
    closeCropperDialog() {
      this.isShowCropper = false;
      this.uploader = [];
      this.uploadUrl = '';
    },
    // 拉取商品详情
    async getDetail() {
      if (this.id) {
        let res = await this.$api.getShopGoods({
          id: this.id,
        });
        if (res.code == 1) {
          this.info = res.data;
          this.dwName = res.data.dw;
          this.info.status = Number(res.data.status);
          this.fileList = [
            {
              url: res.data.img,
            },
          ];
          if (this.dwName == '克' || this.dwName == '斤' || this.dwName == '千克') {
            this.unit_type = 2;
          } else {
            this.unit_type = 1;
          }
        }
      }
    },
    // 商品品分类
    async getGoodsType() {
      let res = await this.$api.goodsType({
        shop_id: localStorage.shop_id,
      });
      if (res.code == 1) {
        this.goodsTypeList = res.data.list.map((item, index) => {
          if (this.info.c_id == item.id) {
            this.goodsTypeName = item.name;
            this.goodsTypePickerIndex = index;
          }
          return {
            text: item.name,
            c_id: item.id,
          };
        });
      }
    },
    async goodsUnit() {
      let res = await this.$api.getProductsUnit({ type: this.unit_type, });
      if (res.code == 1) {
        this.dwList = res.data.map((item, index) => {
          if (this.info.dw == item.name) {
            this.dwName = item.name;
            this.dwPickerIndex = index;
          }
          return {
            text: item.name,
            c_id: item.id,
          };
        });
      }
    },
    // picker确认
    onConfirm(e) {
      if (this.pickerType == 1) {
        this.info.c_id = e.c_id;
        this.goodsTypeName = e.text;
      } else {
        this.info.dw = e.text;
        this.dwName = e.text;
      }

      this.showPicker = false;
    },
    // 上传图片
    async uploadIDcardFront(file) {
      // 此时可以自行将文件上传至服务器
      this.croimgObj = file;
      this.$toast.clear();
      this.isShowCropper = true;
    },
    //打开选择分类弹出层
    openClassify() {
      console.log("this.Classifyopen", this.Classifyopen);
      this.Classifyopen = true;
    },
    // 关闭选择分类弹出层
    choseClassify() {
      this.Classifyopen = false;
    },
    // 选择分类提交
    chooseClassify() {
      this.Classifyopen = false;
    },
    // 取消新增商品
    writeOff() {
      this.$router.push({ name: "index", query: {} });
    },
    // 保存新增商品
    async submit() {
      // console.log('fileList', this.fileList);
      if (this.fileList.length == 0) {
        this.info.img = '';
      }
      if (!this.info.bar_code || this.info.bar_code == '') {
        this.$toast('条码不能为空');
        return;
      }
      if (!this.info.name || this.info.name == '') {
        this.$toast('品名不能为空');
        return;
      }
      if (!this.info.c_id || this.info.c_id == '') {
        this.$toast('分类不能为空');
        return;
      }
      if (!this.info.fact_price || this.info.fact_price == '') {
        this.$toast('售价不能为空');
        return;
      }
      if (!this.info.dw || this.info.dw == '') {
        this.$toast('单位不能为空');
        return;
      }
      if (this.info.pro_type == 1 && (!this.info.stock || this.info.stock == '')) {
        this.$toast('库存不能为空');
        return;
      }
      if (this.name == "addPro") {
        let res = await this.$api.addShopGoods(this.info);
        if (res.code == 1) {
          Toast("操作成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        }
      } else {
        let res = await this.$api.editShopGoods(this.info);
        if (res.code == 1) {
          Toast("操作成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        }
      }
    },
    confirmPopDialog() {
      this.delGoods();
      this.closePopDialog();
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog() {
      this.dialogTitle = "提示";
      this.dialogMsg = `是否确认删除？`;
      this.dialogType = '4';
      this.dialogData = {};
      this.showDialog = true;
    },
    // 删除商品
    async delGoods() {
      let res = await this.$api.delShopGoods({
        p_id: this.id,
      });
      if (res.code == 1) {
        Toast("操作成功");
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }
    },
    // 生成条码
    sc_code() {
      this.bool_code = true;
      this.$api.createBarCode().then((res) => {
        if (res.code == 1) {
          this.bool_code = false;
          this.info.bar_code = res.data.bar_code;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.otherEatNum-nk {
  border-radius: 0.13rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.choose-unit-modal {
	text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
	
	.modal-content-box {
		width: 82rem;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
		position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;
	
		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}

  .mocontent {
    height: 40vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 6.8rem 1rem;
  }

  .cont-title-box {
		display: flex;
		justify-content: center;
    margin-bottom: 3rem;
    width: 100%;
		.cont-title-item {
			text-align: center;
			line-height: 6rem;
			width: 40%;
			height: 6rem;
			border: 0.2rem solid #DEDEDE;
			font-size: 2.6rem;
			color: #666666;
		}
	
		.cont-title-item:nth-child(1) {
			border-right: none;
			border-radius: 0.8rem 0 0 0.8rem;
		}
		.cont-title-item:nth-child(2) {
			border-left: none;
			border-radius: 0 0.8rem 0.8rem 0;
		}
	
		.cont-title-item-ac {
			background: #1588f5;
			border-color: #1588f5;
			color: #fff;
		}
	}

  .unit-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow-y: scroll;
    width: 100%;
    .unit-item {
      padding: 0 2.5rem;
      height: 6rem;
      text-align: center;
      line-height: 6rem;
      border: 0.15rem solid #DEDEDE;
      border-radius: 0.8rem;
      color: #999999;
      font-size: 2.4rem;
      margin-right: 2rem;
      margin-bottom: 2.5rem;
    }
    .actived-unit-item {
      border: 0.15rem solid #1588f5;
      color: #1588f5;
    }
  }
}
.add-required {
  position: relative;
}
.add-required::before {
  content: '*';
  position: absolute;
  left: 1.7rem;
  color: #e60012;
}
.add-required-code {
  position: relative;
}
.add-required-code::before {
  content: '*';
  position: absolute;
  left: 0.7rem;
  color: #e60012;
}
/deep/ .van-uploader__upload {
  width: 20.6rem;
  height: 28rem;
  margin: 0;
}

.pro-type-box {
  width: 146rem;
  margin: 0 auto 5rem;
  font-size: 2.2rem;
  color: #333;
  text-align: left;
  display: flex;
  align-items: center;
  .switch_name {
    width: 14rem;
    text-align: center;
    margin-right: 3rem;
  }

  .pro-choose-box {
    display: flex;
    align-items: center;

    .pro-type-item {
      display: flex;
      align-items: center;
      border: 0.15rem solid #eee;
      padding: 2rem 3rem;
      border-radius: 0.8rem;
    }
  }
}

.foot_box {
  width: 146rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 2.2rem;
  line-height: 6rem;
  height: 6rem;
  align-items: center;
  color: #333;

  .switch {
    display: flex;
    justify-content: space-between;
    width: 30rem;

    .switch_name {
      width: 14rem;
      // background-color: #f4f4f4;
    }

    .switch_type {
      height: 6rem;
      display: flex;
      align-items: center;
    }
  }

}
.foot_btn_box {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #fff;
  z-index: 10;
  height: 10rem;
  align-items: center;
  box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.26);
  left: 0;

  .foot_btn {
    font-size: 2.4rem;
    width: 42rem;
    height: 7rem;
    border-radius: 0.8rem;
    background: #eaeaea;
  }

  .foot_submit {
    color: #fff;
    background-color: #1588f5;
  }
}
.bottom-box-line {
  grid-template-columns: repeat(1, 100%) !important;
  border-top: none !important;
}
.bottom_box {
  width: 146rem;
  // height: 36.9rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
  border: 0.1rem solid #e5e5e5;

  .units {
    height: 7.2rem;
    display: flex;
    font-size: 2rem;
    font-weight: 400;
    color: #333333;
    line-height: 7.2rem;
    border: 0.1rem solid #e5e5e5;

    .units_name {
      height: 7.2rem;
      font-size: 2rem;
      font-weight: 400;
      color: #333333;
      line-height: 7.2rem;
      width: 14rem;
      background-color: #eaeaea;
    }

    .units_input {
      width: calc(100% - 17rem);
      padding-left: 2rem;
      flex: 1;
      height: 7.2rem;
    }

    .a_center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.popBox {
  width: 48rem;
  height: 58rem;
  background: #ffffff;
  font-size: 2.2rem;
  padding-top: 1.5rem;
  text-align: left;

  .pop_title {
    padding-left: 2rem;
    color: #666;
  }

  .pop_index {
    margin-top: 1.5rem;
    border-top: 0.1rem solid #e5e5e5;
    height: 38rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .pop_btn {
    padding: 7rem 7rem 0 7rem;
    display: flex;
    justify-content: space-between;

    .btn {
      width: 27rem;
      height: 5rem;
      border: 0.1rem solid #999999;
      font-size: 2.8rem;
      font-weight: 500;
      color: #999999;
    }

    .submit_btn {
      margin-left: 8.1rem;
      background: linear-gradient(270deg, #1588f5 0%, #1588f5 100%);
      color: #ffffff;
      border: none;
    }
  }
}

.page {
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  overflow: hidden;
  position: relative;

  .page_box {
    position: relative;
    height: calc(100% - 17rem);
    background: #fff;
    padding-top: 6rem;
    margin-top: 1.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
    // padding-top: 5.8rem;
    .top_box {
      margin-bottom: 6rem;
      width: 100%;
      display: flex;
      justify-content: center;

      .uploader {
        // 修改vant上传图片的样式
        margin-right: 5.1rem;
        width: 20.6rem;
        height: 28rem;
        background: #f4f4f4;

        .pro-img {
          width: 100%;
          height: 100%;
        }

        // /deep/ .van-uploader__upload,
        // /deep/ .van-image,
        // /deep/ .van-uploader__input {
        //   width: 20.6rem;
        //   height: 28rem;
        //   background: #f4f4f4;
        // }
        // /deep/ .van-uploader__preview-image {
        //   width: 20.6rem;
        //   height: 26.6rem;
        // }
        // /deep/ .van-uploader__upload-text {
        //   margin-top: 0.8rem;
        //   color: #969799;
        //   font-size: 2rem;
        //   line-height: 6rem;
        //   position: absolute;
        //   font-weight: 100;
        //   bottom: 0;
        //   width: 100%;
        //   height: 6rem;
        //   color: #fff;
        //   text-align: center;
        //   background: linear-gradient(270deg, #fe5600 0%, #ff8f00 100%);
        // }
        // /deep/ .van-image {
        //   background: #e8f6fe;
        //   overflow: hidden;
        // }
        // /deep/ .van-icon__image {
        //   width: 20.6rem;
        //   height: 26.6rem;
        //   -o-object-fit: contain;
        //   object-fit: contain;
        //   margin-bottom: 2.3rem;
        // }
      }

      .add_input {
        width: 119.8rem;
        background: #e5e5e5;
      }

      .input_box {
        position: relative;
        display: flex;
        font-size: 2rem;
        font-weight: 400;
        color: #333333;
        line-height: 7.2rem;
        border-top: 0.1rem solid #e5e5e5;
        border-right: 0.1rem solid #e5e5e5;

        .choose {
          width: 103.2rem;
          background-color: #fff;
          text-align: left;
          padding-left: 2rem;
        }

        .input_name {
          // width: 17rem;
          width: 10rem;
          height: 7.2rem;
          background: #eaeaea;
        }

        .input_index {
          width: 86.2rem;
          height: 7.2rem;
          padding-left: 2rem;
        }

        .input_index1 {
          // width: 103.2rem;
          width: 110rem;
          background-color: #fff;
          padding-left: 2rem;
          height: 7.2rem;
        }

        .input_index2 {
          width: 20.1rem;
          padding-left: 2rem;
          background: #fff;
          height: 7.2rem;
        }

        .input_index3 {
          background: #fff;

          /deep/ .van-field__body {
            height: 100%;
            font-size: 2rem;
          }
        }

        .input_btn {
          padding: 0 3rem;
          height: 7.2rem;
          background: #1588f5;
          font-size: 2.6rem;
          font-weight: 100;
          color: #ffffff;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .border_btm {
        border-bottom: 0.1rem solid #e5e5e5;
      }
    }
  }
}
.delGoods {
  text-align: right;
  width: 146rem;
  margin: 4rem auto;
}
/deep/.van-uploader {
  .van-image {
    width: 20.6rem;
    height: 28rem;
    img {
      width: 20.6rem;
      height: 28rem;
    }
  }
}
/deep/.van-picker__cancel,
/deep/.van-picker__confirm,
/deep/.van-picker-column {
  font-size: 2.4rem;
}
.popups {
  width: 40rem;
  height: 16rem;
  background: #ffffff;
  font-size: 2.5rem;
  text-align: center;
}
</style>
